.password-reset-page {
  background-image: url('../../images/image 26.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 100px);

  .password-reset-page-headline {
    font-weight: 900;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .password-reset-page-form-container {
    background: #f1f1f1;
    border: 1px solid #00ae41;
    border-radius: 5px;
    padding: 40px 50px;
  }
}
