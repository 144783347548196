@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css');
@import '~antd/dist/antd.css';

:root {
  --p-color: #0f7785;
  --left-bar-size: 70px;
  --top-bar-size: 100px;
}

.reset-btn {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  /* Remove excess padding and border in Firefox 4+ */

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:focus {
    outline: none;
  }
}

html,
body {
  font-family: 'Roboto', sans-serif;
  color: #000000;
}

.App {
  padding-top: var(--top-bar-size);

  &.sidebar-yes {
    padding-left: var(--left-bar-size);
  }

  &.sidebar-no {
  }
}

.brand-color {
  color: var(--p-color);
}

#swania-surface {
  min-height: 75%;
  width: 100%;
  height: auto;
}

#form-login-inline {
  justify-content: flex-end;
  display: flex;
  margin-right: 30px;
}

#register-form {
  display: flex;
  justify-content: right;
  flex-direction: row-reverse;
}

.inscription-link {
  flex-direction: column;
}

.page_head {
  font-weight: 900;
  font-size: 30px;
  line-height: 42px;
  text-transform: uppercase;
  margin: 0;
}

.page_head_sub {
  font-size: 20px;
  line-height: 28px;
  margin: 0;
}

.page_head_2 {
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  margin: 0;
}

.page_head_3 {
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  margin: 0;
}

.font-smallest {
  font-size: 0.7em;
}

.font-smallest2 {
  font-size: 0.85em;
}

.custom-text-area-group {
  position: relative;
}

.custom-text-area-group .flag-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}

.custom-text-area-group textarea {
  padding-left: 37px;
}

.custom-text-area-group input {
  padding-left: 40px;
}

.bg_block {
  background-color: rgba(0, 174, 65, 0.08);
}

.rect_box_wrapper {
}

.rect_box_title {
  font-weight: 500;
}

.rect_box {
  background: #e5e5e5;
  height: 225px;
  position: relative;
  margin-bottom: 30px;
}

.rect_box .rect-lbl {
  background: var(--p-color);
  color: white;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  text-align: center;
  line-height: 50px;
  font-size: 35px;
  font-weight: 100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.divider {
  border: 1px solid #c4c4c4;
}

.form-footer {
}

.form-footer .btn-custom,
.form-footer .btn-outline-custom {
  min-width: 225px;
  margin: 10px 0 10px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.7;
  border: 1px solid var(--p-color);
}

.form-footer .btn-custom {
  background: var(--p-color);
  color: white;
}

.form-footer .btn-outline-custom {
  color: var(--p-color);
}

.preview_container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #e5e5e5;
}

.preview_close {
  background: var(--p-color);
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
  z-index: 10;
}

.prev_image {
  display: block;
  object-fit: scale-down;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.prev_video {
  display: block;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.prev_file {
  position: absolute;
  top: 50%;
  left: 15px;
  right: 10px;
  transform: translate(0, -50%);
}

.prev_file a {
  display: block;
  position: relative;
  padding-left: 35px;
  word-break: break-word;
  color: var(--p-color);
}

.prev_file a:hover {
  text-decoration: none;
}

.prev_file a img {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
}

.prev_file a span {
}

.preview_spinner {
  background: #e5e5e5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.preview_spinner i {
  color: var(--p-color);
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
}

.spinner-wrapper {
  text-align: center;
}

.spinner-wrapper i {
  font-size: 30px;
}

.top-bar {
  border-bottom: 1px solid #c4c4c4;
  padding: 17px 0;
  background: white;
}

.top-bar-left-link,
.top-bar-right-link {
  color: var(--p-color);
  font-size: 16px;
  text-decoration: none;
  line-height: 27px;
  display: inline-block;
}

.top-bar-left-link {
  font-weight: 400;
}

.top-bar-left-link i {
  font-weight: 900;
  font-style: normal;
  display: inline-block;
  padding-right: 1px;
}

.top-bar-right-link {
  font-weight: 500;
  margin-left: 20px;
}

.top-bar-right-link i {
  background: var(--p-color);
  height: 27px;
  width: 27px;
  display: inline-block;
  text-align: center;
  line-height: 24px;
  border-radius: 100%;
  font-weight: 200;
  font-style: normal;
  color: white;
  font-size: 18px;
  margin-right: 5px;
}

.top-bar-left-link:hover,
.top-bar-right-link:hover {
  text-decoration: none;
}

.top-bar-right-link i img {
  text-align: center;
  width: 15px;
}

.action_btn {
  background: var(--p-color);
  height: 27px;
  width: 27px;
  display: inline-block;
  text-align: center;
  line-height: 27px;
  border: 0;
}

.action_btn img {
  text-align: center;
  width: 15px;
}

.action-icon {
  background: var(--p-color);
  height: 27px;
  width: 27px;
  display: inline-block;
  text-align: center;
  line-height: 27px;
}

.action-icon img {
  text-align: center;
  width: 15px;
}

.btn-custom-common {
  background: var(--p-color);
  color: white;
  margin: 10px 0 10px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.7;
  border: 1px solid var(--p-color);
}

.btn-custom-common:hover {
  color: white;
}

.btn-custom-outline-common {
  background: white;
  color: var(--p-color);
  margin: 10px 0 10px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.7;
  border: 1px solid var(--p-color);
}

.btn-custom-outline-common:hover {
  color: var(--p-color);
}

.action_btn_with_txt {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  outline: none;
  font-size: 16px;
  color: var(--p-color);
  font-weight: 500;
}

.action_btn_with_txt:active,
.action_btn_with_txt:hover {
  border: 0;
  outline: none;
  text-decoration: none;
}

.brand-modal .modal-title {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 900;
  margin-top: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.brand-modal .modal-close-btn {
  background: white;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  box-shadow: none;
}

.brand-modal .modal-close-btn:active {
  outline: none;
}

.brand-modal .modal-close-btn img {
  width: 14px;
}

.brand-table {
}

.brand-table thead tr th {
  color: #5f5f5f;
  font-weight: normal;
}

.brand-table tbody tr td {
  color: #000000;
}

.brand-table.table-striped tbody tr:nth-of-type(odd) {
  background: #f1f1f1;
}

.brand_pagination {
  a {
    outline: none !important;

    &:hover {
      outline: none;
    }
  }

  .pagination {
    & > li > a,
    & > li > span {
      border: 0;
      font-weight: 500;
      color: var(--p-color);
      cursor: pointer;
    }

    & > .active > a,
    & > .active > a:focus,
    & > .active > a:hover,
    & > .active > span,
    & > .active > span:focus,
    & > .active > span:hover {
      color: #000;
      background-color: #f1f1f1;
    }
  }

  .page-link {
    border: 0;
  }
}

.common_search_container {
  margin-bottom: 60px;

  .common_search_input_wrapper {
    position: relative;
    max-width: 500px;
    margin: auto;

    .common_search_input_icon {
      position: absolute;
      top: 12px;
      left: 25px;
    }

    .common_search_input {
      font-size: 18px;
      display: block;
      width: 100%;
      border: 1px solid #e8e8e8;
      padding: 12px 20px 12px 70px;
      font-style: italic;
      border-radius: 5px;
    }
  }
}

.brand-btn-5 {
  color: var(--p-color);
  background: transparent;
  border: 0;
  outline: 0;
  display: inline-block;

  img {
    width: 24px;
    vertical-align: middle;
    margin-right: 5px;
    display: inline-block;
    margin-top: -3px;
  }

  &:active,
  &:hover {
    outline: none;
    text-decoration: none;
  }
}

.pop-msg-con {
  p {
    font-weight: normal;
    font-size: 16px;
  }

  .pop-msg-con-h {
    font-weight: 500;
    font-size: 18px;
  }
}

.border-less {
  border: 0;
}

.min-width-200 {
  min-width: 200px;
}

.copy_pass_btn {
  font-weight: normal;
  font-size: 16px;
  color: #0f7785;
  white-space: nowrap;
}

.copied_pass_lbl {
  font-weight: 500;
  font-size: 16px;
  color: #00ae41;

  img {
    position: relative;
    top: -2px;
  }
}

.empty-item {
  text-align: center;

  img {
    width: 50px;
  }

  p {
    margin-top: 20px;
    font-style: italic;
    font-weight: normal;
    font-size: 24px;
    color: #979797;
  }
}

.col-form-label {
  font-weight: 500;
}

.font-weight-500 {
  font-weight: 500;
}

.no-underscore:hover {
  text-decoration: none;
}

#checkboxgroup #multigroup {
  margin: 0 auto;
  width: 300px !important;
  padding-top: 15px;
}